<template>
  <div id="ProducerShowsSales">
    <QsCard :qlik-ids="['DZRpytH']" style="grid-column: 1 / span 2" />

    <QsCard :qlik-ids="['vjxsFL']" />
    <QsCard :qlik-ids="['mGJLkmg']" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#ProducerShowsSales {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 300px 350px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
